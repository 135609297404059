import React from 'react';
import { NavBar } from '../../components/NavBar';
import { Banner } from '../../components/Banner';
import { Skills } from '../../components/Skills';
import { Projects } from '../../components/Projects';
import { Contact } from '../../components/Contact';
import { Footer } from '../../components/Footer';
import { Helmet } from 'react-helmet'; // Import Helmet
import './home.css';
import ReconComponent from '../../components/Recon';

function Home() {
    return (
        <div>
            <Helmet>
                <title>Zeroday Ops - Home</title>
                <meta name="description" content="Welcome to Zeroday Ops, your trusted partner in cybersecurity solutions. Explore our skills, projects, and contact us for more information." />
                <meta name="keywords" content="Cybersecurity, Zeroday Ops, Projects, Skills, Contact" />
            </Helmet>
            <NavBar />
            <Banner />
            {/* <ReconComponent /> */}
            <Skills />
            <Projects />
            <Contact />
            <Footer />
        </div>
    );
}

export default Home;
