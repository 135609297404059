import React from 'react';
import './appsec.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import web from '../../assets/img/websec.png';
import mobile from '../../assets/img/mobilesec.png';
import thickclient from '../../assets/img/thickclient.png';
import sourcecode from '../../assets/img/sourcecode.png';
import api from '../../assets/img/api.png';
import appsec from '../../assets/img/appsec.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Contact } from '../../components/Contact';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet'; // Import Helmet for SEO

const WirelessNetworkAssessment = () => {
    return (
        <>
            <Helmet>
                <title>Zeroday Ops - Application Security Assessment Services</title>
                <meta name="description" content="Explore our comprehensive Application Security Assessment Services to identify vulnerabilities and enhance your application security." />
                <meta name="keywords" content="Application Security, Cybersecurity, Web Application Security, Mobile Application Security, Penetration Testing, API Security" />
                {/* Organization Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "ZeroDay Ops",
          "url": "https://zerodayops.com/",
          "logo": "https://zerodayops.com/assets/img/logo192.png",
          "sameAs": [
            "https://www.linkedin.com/company/zeroday-ops/",
            "https://www.instagram.com/zerodayops/"
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-9718047774",
            "contactType": "Customer Support",
            "areaServed": "US",
            "availableLanguage": "English"
          }
        }`}</script>

                {/* Breadcrumb Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://zerodayops.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Services",
              "item": "https://zerodayops.com/services"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Application Security",
              "item": "https://zerodayops.com/services/appsec"
            }
          ]
        }`}</script>

                {/* FAQ Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "What is Application Security?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Application Security involves measures to secure applications by finding, fixing, and preventing vulnerabilities."
              }
            },
            {
              "@type": "Question",
              "name": "Why is Application Security important?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Application Security is crucial to protect data, prevent breaches, and maintain the integrity of the application."
              }
            }
          ]
        }`}</script>
            </Helmet>
            <NavBar />
            <div className="appSec-container">
                <br />
                <div className="appSec-header">
                    <h1 className="appSec-title">
                        APPLICATION SECURITY ASSESSMENT SERVICES
                    </h1>
                    <p className="appSec-subtitle">
                        The application security assessment results in a comprehensive report detailing all identified vulnerabilities and providing recommendations for mitigating risks and enhancing the security of your applications.
                    </p>
                </div>
                <br />
                <div className="appSec-section">
                    <div className="appSec-overview">
                        <h2 className="appSec-overview-title">Application Security Assessment Services: Overview</h2>
                        <p className="appSec-overview-text">
                            Securing your applications is essential to protect your sensitive data and maintain the integrity of your information system. Inadequate security measures or misconfigurations can expose your applications to unauthorized access and exploitation. This highlights the importance of regularly monitoring and conducting thorough Application Security assessments.
                        </p>
                    </div>
                    <div className="appSec-image">
                        <img src={appsec} style={{ width: '250px' }} alt="Application Security Assessment" />
                    </div>
                </div>
                <button className='appsecButton'><Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>Get a Quote <ArrowRightCircle size={25} /></Link></button>
                <br />
                <br />
                <div className="appSec-overview">
                    <h2 className="appSec-overview-title">Types of Application Security Services We Perform</h2>
                    <br />
                    <br />
                    <h4>Web Application Security</h4>
                    <p className="appSec-overview-text">
                        Web applications are a common target for cyber threats due to their accessibility over the internet. Ensuring their security involves testing for vulnerabilities like SQL injection, cross-site scripting (XSS), and other web-specific risks.
                    </p>
                    <br />
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={web} alt="Web Application Security" style={{ width: "50%", height: "50%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <br />
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Identifying Input Validation Issues</li>
                                    <li>Analyzing Authentication and Session Management</li>
                                    <li>Assessing Data Encryption Practices</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Testing for Common Web Vulnerabilities (OWASP Top 10)</li>
                                    <li>Providing Mitigation Strategies</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <h4>Mobile Application Security</h4>
                    <p className="appSec-overview-text">
                        Mobile applications often store sensitive data on devices and communicate with servers, making them vulnerable to various attacks. Security assessments focus on protecting user data, ensuring secure communication, and preventing unauthorized access.
                    </p>
                    <br />
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={mobile} alt="Mobile Application Security" style={{ width: "50%", height: "50%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Analyzing Mobile Data Storage Practices</li>
                                    <li>Testing API Security and Server Communication</li>
                                    <li>Assessing Authentication and Authorization Controls</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Evaluating Secure Code Practices</li>
                                    <li>Reviewing App Permissions and Data Leakage Risks</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <h4>Thick Client Penetration Testing (PT)</h4>
                    <p className="appSec-overview-text">
                        Thick client applications, which perform many processes on the client side, require a deep assessment of both the client and server components to identify security vulnerabilities.
                    </p>
                    <br />
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={thickclient} alt="Thick Client Penetration Testing" style={{ width: "50%", height: "50%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Identifying Client-Side Vulnerabilities</li>
                                    <li>Analyzing Data Transmission Security</li>
                                    <li>Assessing Secure Storage and Configuration</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Testing for Application Logic Flaws</li>
                                    <li>Evaluating Server-Side Controls and Communication Security</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <h4>Source Code Review</h4>
                    <p className="appSec-overview-text">
                        A source code review is essential for identifying security flaws at the code level before they can be exploited. This service provides an in-depth analysis of your application's codebase to uncover potential vulnerabilities.
                    </p>
                    <br />
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={sourcecode} alt="Source Code Review" style={{ width: "50%", height: "50%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Conducting Static Code Analysis</li>
                                    <li>Identifying Hard-Coded Secrets and Sensitive Data Exposure</li>
                                    <li>Assessing Input Validation and Output Encoding</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Reviewing Authentication and Authorization Logic</li>
                                    <li>Evaluating Secure Coding Practices and Compliance with Standards</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <h4>API Security</h4>
                    <p className="appSec-overview-text">
                        APIs are integral to modern applications but are often overlooked in security assessments. Securing APIs involves ensuring that they are protected against unauthorized access, data breaches, and other potential threats.
                    </p>
                    <br />
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={api} alt="API Security" style={{ width: "50%", height: "50%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Identifying Insecure API Endpoints</li>
                                    <li>Assessing Authentication and Authorization Mechanisms</li>
                                    <li>Testing for Rate Limiting and Data Leakage</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Analyzing Secure Data Transmission and Encryption</li>
                                    <li>Providing Recommendations for API Hardening</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
                <Contact />
                <Footer />
            </div>
        </>
    );
};

export default WirelessNetworkAssessment;
