import "./contact.css";
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import axios from 'axios';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import profile1 from '../../assets/image1.png';
import profile2 from '../../assets/image3.png';
import profile3 from '../../assets/image2.png';
import profile4 from '../../assets/image3.png';
import fin from "../../assets/img/Finance.png";
import ecom from "../../assets/img/ecommerce.png";
import health from "../../assets/img/HealthCare.png";
import education from "../../assets/img/Education.png";

const ContactPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const name = useRef();
  const email = useRef();
  const company = useRef();
  const message = useRef();

  const [showCalendlyModal, setShowCalendlyModal] = useState(false);
  const submitHandler = async (e) => {
    e.preventDefault();
    const newContact = {
      name: name.current.value,
      email: email.current.value,
      company: company.current.value,
      message: message.current.value
    };

    try {
      await axios.post(`https://api.zerodayops.com/api/contact`, newContact);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };
  const handleBookAppointment = (e) => {
    e.preventDefault();
    setShowCalendlyModal(true); // Show modal on button click
  };

  const closeModal = () => {
    setShowCalendlyModal(false); // Close the modal
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Zeroday Ops</title>
        <meta name="description" content="Get in touch with Zeroday Ops for cybersecurity inquiries, quotes, and more." />
        <meta name="keywords" content="Contact, Zeroday Ops, Cybersecurity, Inquiry, Quote" />
      </Helmet>
        {/* <NavBar /> */}
      <div className="centered contact-page-color">
        <section className="cta-section">
          <div className="header centered">
            <h1>Prevent Cybersecurity Threats <br />Before They Happen</h1>
          </div>
          <div className="cta-sub-section">
            <p>
              We will Identify and help you Fix Vulnerabilities for Free <br />in Our Exclusive 1 on 1 session for Business owners.
            </p>
          </div>
          <div className="cta-button-cover">
            <a href="#" className="cta-button" onClick={handleBookAppointment}>
              Book An Appointment
            </a>
          </div>
          <div>
            <p className="cta-note">
              Book a session to save over 10 lakhs <br />worth of financial loss
            </p>
          </div>
          <div className="header centered">
            <h4 className="cta-lower">
            JOIN OUR EXPERT-LED SESSION TO DISCOVER ACTIONABLE STRATEGIES TO SAFEGUARD YOUR COMPANY'S DIGITAL ASSETS.
            </h4>
          </div>
          <div className="cta-cards-container">
            <div className="cta-card">
              <div className="header centered">
                <h5>Identify Vulnerabilities</h5>
              </div>
              <p>Identify the most common security vulnerabilities.</p>
            </div>
            {/* <div className="cta-card">
              <div className="header centered">
                <h5>Free Strategies</h5>
              </div>
              <p>Free, actionable strategies to protect your business.</p>
            </div> */}
            <div className="cta-card">
              <div className="header centered">
              <h5>Safeguard Your Product</h5>
              </div>
              <p>Safeguard your digital product.</p>
            </div>
            <div className="cta-card">
              <div className="header centered">
              <h5>Real-World Examples</h5>
              </div>
              <p>See real-world examples of vulnerabilities and how to fix them.</p>
            </div>
            <div className="cta-card">
              <div className="header centered">
                <h5>Free Assessment Tool</h5>
              </div>
              <p>Gain access to our free vulnerability assessment tool for your business.</p>
            </div>
            <div className="cta-card">
              <div className="header centered">
                <h5>Zeroday Certified Secure Asset</h5>
              </div>
              <p>Receive a certification proving your digital asset is vulnerability-free.</p>
            </div>
          </div>        
        </section>

        <section className="impact-section">
          <div className="header centered">
            <h2>What Our Impact Could Look Like</h2>
          </div>
          <div className="stats-container">
            <div className="stat-box">
              <h3>10+</h3>
              <p>Common Vulnerabilities</p>
            </div>
            <div className="stat-box">
              <h3>30L</h3>
              <p>Saved Financial Loss</p>
            </div>
            <div className="stat-box">
              <h3>95%</h3>
              <p>Reduction in Data Loss</p>
            </div>
          </div>
          {/* New Section for "Immediate Attention" */}
          <div className="attention-section">
            <h3>Companies That Require Immediate Attention</h3>
            <div className="attention-cards">
              <div className="attention-card">
                <img src={fin} alt="Financial Services Icon" />
                <p>Financial Services</p>
              </div>
              <div className="attention-card">
                <img src={ecom} alt="E-Commerce Icon" />
                <p>E-Commerce</p>
              </div>
              <div className="attention-card">
                <img src={health} alt="Healthcare Icon" />
                <p>Healthcare</p>
              </div>
              <div className="attention-card">
                <img src={education} alt="Education Icon" />
                <p>Education</p>
              </div>
            </div>
          </div>
        </section>
	      {/* FAQ Section */}
        <section className="faq-section">
          <h3>Frequently Asked Questions</h3>
          <div className="faq-item">
            <button className="faq-question" onClick={() => toggleFAQ(0)}>
              Any Experiences Needed To Book The Slot?
            </button>
            <div className={`faq-answer ${activeIndex === 0 ? 'active' : ''}`}>
              <p>No specific experience is required, but basic knowledge of your company's security infrastructure can be helpful.</p>
            </div>
          </div>
          <div className="faq-item">
            <button className="faq-question" onClick={() => toggleFAQ(1)}>
              Will I Receive Anything From The Session That I Can Use?
            </button>
            <div className={`faq-answer ${activeIndex === 1 ? 'active' : ''}`}>
              <p>Yes, you will receive a detailed report highlighting potential vulnerabilities and customized recommendations.</p>
            </div>
          </div>
          <div className="faq-item">
            <button className="faq-question" onClick={() => toggleFAQ(2)}>
              Why Should We Listen to You?
            </button>
            <div className={`faq-answer ${activeIndex === 2 ? 'active' : ''}`}>
              <p>Our expert team has extensive experience working with companies to improve cybersecurity, and our clients trust us for proven results.</p>
            </div>
          </div>
          <div className="faq-item">
            <button className="faq-question" onClick={() => toggleFAQ(3)}>
              What Can You Expect Post-Meeting?
            </button>
            <div className={`faq-answer ${activeIndex === 3 ? 'active' : ''}`}>
              <p>You will receive a follow-up email with all key findings and actionable steps tailored to your business needs.</p>
            </div>
          </div>

          <h3>Testimonials</h3>
          <div className="profile-cards-container">
            <div className="profile-card">
              <img src={profile1} alt="User Profile" />
              <p>Cath, CTO at Voyapro Journeys</p>
              <p>Zeroday Ops provided us with invaluable insights into our security vulnerabilities. Their team's expertise in penetration testing helped us address critical issues before they became major problems. Highly recommend their services!</p>
            </div>
            <div className="profile-card">
              <img src={profile2} alt="User Profile" />
              <p>Gurmeet, CEO of Go Growth</p>
              <p>The level of service we received from Zeroday Ops was exceptional. Their thoroughness and attention to detail in our security assessment gave us confidence in our cybersecurity posture. We're grateful for their support!</p>
            </div>
            <div className="profile-card">
              <img src={profile3} alt="User Profile" />
              <p>Niko, Operations Manager at Secure Corp</p>
              <p>Zeroday Ops transformed our approach to cybersecurity. Their tailored solutions not only safeguarded our data but also educated our team on best practices. A fantastic partner for any business looking to enhance security!</p>
            </div>
	        </div>
	  <br/>
          <br/>   
          <h3>Book A Session To Save Over 10 Lakhs</h3>
          <h3>Worth Of Financial Loss</h3>   
          <div className="cta-button-cover">
            <div className="center">
                <a href="#" className="cta-button" onClick={handleBookAppointment}>
                  Book An Appointment
                </a>
            </div>
          </div>         
        </section>
      </div>
      {/* Modal for Calendly iframe */}
      {showCalendlyModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <iframe
              src="https://calendly.com/zerodayops/schedule-a-call?back=1&month=2024-10"
              style={{ width: '100%', height: '600px', border: 'none' }}
              frameBorder="0"
              scrolling="no"
              title="Book An Appointment"
              allowTransparency="true">
            </iframe>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ContactPage;
